import React from 'react'

export const Fonts = () => (
	<style jsx global>
		{`
			@font-face {
				font-family: 'synthese';
				src: url('/fonts/synthese/synthese-light.woff2') format('woff2');
				font-display: block;
				font-weight: 200;
			}

			@font-face {
				font-family: 'synthese';
				src: url('/fonts/synthese/synthese-book.woff2') format('woff2');
				font-display: block;
				font-weight: 300;
			}
			@font-face {
				font-family: 'synthese';
				src: url('/fonts/synthese/synthese-regular.woff2') format('woff2');
				font-display: block;
				font-weight: 400;
			}

			@font-face {
				font-family: 'synthese-oblique';
				src: url('/fonts/synthese/syntheseweb-oblique.woff2') format('woff2');
				font-display: block;
				font-weight: 400;
			}

			@font-face {
				font-family: 'synthese-bold-oblique';
				src: url('/fonts/synthese/synthese-boldoblique.woff2') format('woff2');
				font-display: block;
				font-weight: 400;
			}
		`}
	</style>
)
