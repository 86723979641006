import { createGlobalStyle, css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { above } from '../../../styles/tools/media';

const GlobalStyle = createGlobalStyle`
	:root {
		--primaryForeground: ${props => props.theme.primaryForeground};
		--primaryBackground: ${props => props.theme.primaryBackground};
		--primaryAccent: ${props => props.theme.primaryAccent};
		--rippleBase: ${props => props.theme.rippleBase};
		--rippleTint: ${props => props.theme.rippleTint};
		--secondaryForeground: ${props => props.theme.secondaryForeground};
		--secondaryBackground: ${props => props.theme.secondaryBackground};
		--secondaryAccent: ${props => props.theme.secondaryAccent};
		--black: ${props => props.theme.black};
		--grey75: ${props => props.theme.grey75};
		--grey30: ${props => props.theme.grey30};
		--grey15: ${props => props.theme.grey15};
		--grey5: ${props => props.theme.grey5};
		--white: ${props => props.theme.white};
		--errorForeground: ${props => props.theme.errorForeground};
		--errorBackground: ${props => props.theme.errorBackground};
		--validForeground: ${props => props.theme.validForeground};
		--validBackground: ${props => props.theme.validBackground};
	}

	*,
	*::before,
	*::after {
		margin: 0;
		box-sizing: border-box;
	}

	html {
		font-size: 16px;
	}

	body {
		font-family: 'synthese', Arial, sans-serif;
	}

	svg {
		pointer-events: none;
	}

	.lazyload,
	.lazyloaded,
	.lazyloading {
		transition: opacity 0.3s ease-in-out;
	}

	.lazyload,
	.lazyloading {
		opacity: 0
	}

	.lazyloaded {
		opacity: 1;
	}

	main{
		margin-top:48px;

		${above(
			breakpoints[600],
			() => css`
				margin-top: 64px;
			`
		)}

		${above(
			breakpoints[1440],
			() => css`
				margin-top: 72px;
			`
		)}
	}

	img[height="1"][width="1"] {
		display: none;
	}
`;

export default GlobalStyle;
