const breakpoints = {
	600: 600,
	768: 768,
	960: 960,
	1280: 1280,
	1440: 1440,
	1920: 1920,
}

export default breakpoints
