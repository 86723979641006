'use client';

import React from 'react';
import {StyleSheetManager, ThemeProvider} from 'styled-components';
import GlobalStyle from '@/components/molecules/PageContainer/index.style';
import {Fonts} from '@/styles/fonts';
import isPropValid from '@emotion/is-prop-valid';

export default function NextThemeProvider({children, theme}: { children: React.ReactNode, theme: any }) {

	return (
		<StyleSheetManager shouldForwardProp={prop => isPropValid(prop)}>
			<ThemeProvider theme={theme}>
				<GlobalStyle/>
				{children}
				<Fonts/>
			</ThemeProvider>
		</StyleSheetManager>
	);
}
