import colors from './colors';

export type ThemesType = typeof themes;
// {
// 	blue1: {
// 		[key: string]: string;
// 	};
// 	blue2: {
// 		[key: string]: string;
// 	};
// 	green1: {
// 		[key: string]: string;
// 	};
// 	green2: {
// 		[key: string]: string;
// 	};
// 	group1: {
// 		[key: string]: string;
// 	};
// 	group2: {
// 		[key: string]: string;
// 	};
// 	group3: {
// 		[key: string]: string;
// 	};
// 	group4: {
// 		[key: string]: string;
// 	};
// 	group5: {
// 		[key: string]: string;
// 	};
// 	group6: {
// 		[key: string]: string;
// 	};
// 	purple1: {
// 		[key: string]: string;
// 	};
// 	purple2: {
// 		[key: string]: string;
// 	};
// 	scitech1: {
// 		[key: string]: string;
// 	};
// 	scitech2: {
// 		[key: string]: string;
// 	};
// 	scitech3: {
// 		[key: string]: string;
// 	};
// 	scitech4: {
// 		[key: string]: string;
// 	};
// 	scitech5: {
// 		[key: string]: string;
// 	};
// 	scitech6: {
// 		[key: string]: string;
// 	};
// 	works1: {
// 		[key: string]: string;
// 	};
// 	works2: {
// 		[key: string]: string;
// 	};
// 	works3: {
// 		[key: string]: string;
// 	};
// 	works4: {
// 		[key: string]: string;
// 	};
// 	works5: {
// 		[key: string]: string;
// 	};
// 	works6: {
// 		[key: string]: string;
// 	};
// };

const themes = {
	blue1: {
		primaryForeground: colors.base.blue,
		primaryBackground: colors.base.turquoise,
		primaryAccent: colors.base.red,
		rippleBase: colors.base.red,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.base.blue,
		secondaryBackground: colors.lowTint.turquoise,
		secondaryAccent: colors.base.turquoise,
		...colors.monochrome,
		...colors.messaging,
	},
	blue2: {
		primaryForeground: colors.base.blue,
		primaryBackground: colors.highTint.pink,
		primaryAccent: colors.base.purple,
		rippleBase: colors.base.purple,
		rippleTint: colors.highTint.purple,
		secondaryForeground: colors.base.blue,
		secondaryBackground: colors.lowTint.beige,
		secondaryAccent: colors.highTint.pink,
		...colors.monochrome,
		...colors.messaging,
	},
	green1: {
		primaryForeground: colors.base.green,
		primaryBackground: colors.lowTint.orange,
		primaryAccent: colors.base.orange,
		rippleBase: colors.base.orange,
		rippleTint: colors.highTint.orange,
		secondaryForeground: colors.base.green,
		secondaryBackground: colors.lowTint.beige,
		secondaryAccent: colors.lowTint.orange,
		...colors.monochrome,
		...colors.messaging,
	},
	green2: {
		primaryForeground: colors.base.green,
		primaryBackground: colors.base.paleYellow,
		primaryAccent: colors.base.magenta,
		rippleBase: colors.base.magenta,
		rippleTint: colors.highTint.magenta,
		secondaryForeground: colors.base.green,
		secondaryBackground: colors.lowTint.beige,
		secondaryAccent: colors.base.paleYellow,
		...colors.monochrome,
		...colors.messaging,
	},
	group1: {
		primaryForeground: colors.base.green2,
		primaryBackground: colors.lowTint.paleRed,
		secondaryBackground: colors.lowTint.redTint,
		primaryAccent: colors.highTint.pink2,
		rippleTint: colors.highTint.green2,
		rippleBase: colors.monochrome.white,
		...colors.monochrome,
		...colors.messaging,
	},
	group2: {
		primaryForeground: colors.base.green2,
		primaryBackground: colors.lowTint.yellow2,
		secondaryBackground: colors.lowTint.redTint,
		primaryAccent: colors.base.teal,
		rippleTint: colors.highTint.green2,
		rippleBase: colors.monochrome.white,
		...colors.monochrome,
		...colors.messaging,
	},
	group3: {
		primaryForeground: colors.base.green2,
		primaryBackground: colors.lowTint.orange2,
		secondaryBackground: colors.lowTint.redTint,
		primaryAccent: colors.highTint.pink2,
		rippleTint: colors.lowTint.redTint,
		rippleBase: colors.monochrome.white,
		...colors.monochrome,
		...colors.messaging,
	},
	group4: {
		primaryForeground: colors.base.green2,
		primaryBackground: colors.highTint.blue2,
		secondaryBackground: colors.lowTint.pink2,
		primaryAccent: colors.base.blue2,
		rippleTint: colors.lowTint.pink2,
		rippleBase: colors.monochrome.white,
		...colors.monochrome,
		...colors.messaging,
	},
	group5: {
		primaryForeground: colors.base.green2,
		primaryBackground: colors.lowTint.pink2,
		secondaryBackground: colors.lowTint.blue2,
		primaryAccent: colors.base.purple2,
		rippleTint: colors.lowTint.blue2,
		rippleBase: colors.monochrome.white,
		...colors.monochrome,
		...colors.messaging,
	},
	group6: {
		primaryForeground: colors.base.green2,
		primaryBackground: colors.highTint.green2,
		secondaryBackground: colors.lowTint.redTint,
		primaryAccent: colors.highTint.pink2,
		rippleTint: colors.lowTint.redTint,
		rippleBase: colors.monochrome.white,
		...colors.monochrome,
		...colors.messaging,
	},
	purple1: {
		primaryForeground: colors.base.purple,
		primaryBackground: colors.lowTint.cyan,
		primaryAccent: colors.highTint.green,
		rippleBase: colors.base.green,
		rippleTint: colors.highTint.green,
		secondaryForeground: colors.base.purple,
		secondaryBackground: colors.lowTint.turquoise,
		secondaryAccent: colors.lowTint.cyan,
		...colors.monochrome,
		...colors.messaging,
	},
	purple2: {
		primaryForeground: colors.base.purple,
		primaryBackground: colors.highTint.orange,
		primaryAccent: colors.base.blue,
		rippleBase: colors.base.blue,
		rippleTint: colors.highTint.blue,
		secondaryForeground: colors.base.purple,
		secondaryBackground: colors.lowTint.pink,
		secondaryAccent: colors.highTint.orange,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech1: {
		headerBackground: colors.base.teal,
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.highTint.teal,
		primaryAccent: colors.base.blue2,
		secondaryForeground: colors.monochrome.black,
		secondaryBackground: colors.lowTint.teal,
		secondaryAccent: colors.base.blue2,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech2: {
		headerBackground: colors.base.teal,
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.lowTint.pink2,
		primaryAccent: colors.base.purple2,
		secondaryForeground: colors.monochrome.black,
		secondaryBackground: colors.lowTint.blue2,
		secondaryAccent: colors.base.purple2,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech3: {
		headerBackground: colors.base.teal,
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.highTint.green2,
		primaryAccent: colors.base.green2,
		secondaryForeground: colors.monochrome.black,
		secondaryBackground: colors.lowTint.green2,
		secondaryAccent: colors.base.green2,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech4: {
		headerBackground: colors.base.teal,
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.highTint.blue2,
		primaryAccent: colors.base.blue2,
		secondaryForeground: colors.monochrome.black,
		secondaryBackground: colors.lowTint.blue2,
		secondaryAccent: colors.base.blue2,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech5: {
		headerBackground: colors.base.teal,
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.highTint.teal,
		primaryAccent: colors.base.purple2,
		secondaryForeground: colors.monochrome.black,
		secondaryBackground: colors.lowTint.blue2,
		secondaryAccent: colors.base.purple2,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech6: {
		headerBackground: colors.base.teal,
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.highTint.green2,
		primaryAccent: colors.base.green2,
		secondaryForeground: colors.monochrome.black,
		secondaryBackground: colors.highTint.teal,
		secondaryAccent: colors.base.green2,
		...colors.monochrome,
		...colors.messaging,
	},
	works1: {
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.highTint.paleOrange,
		secondaryBackground: colors.lowTint.redTint,
		primaryAccent: colors.highTint.orange2,
		...colors.monochrome,
		...colors.messaging,
	},
	works2: {
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.lowTint.paleRed,
		secondaryBackground: colors.highTint.paleOrange,
		primaryAccent: colors.highTint.pink2,
		...colors.monochrome,
		...colors.messaging,
	},
	works3: {
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.lowTint.paleRed,
		secondaryBackground: colors.lowTint.redTint,
		primaryAccent: colors.highTint.pink2,
		...colors.monochrome,
		...colors.messaging,
	},
	works4: {
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.lowTint.orange2,
		secondaryBackground: colors.lowTint.orange3,
		primaryAccent: colors.highTint.orange2,
		...colors.monochrome,
		...colors.messaging,
	},
	works5: {
		primaryForeground: colors.monochrome.black,
		primaryBackground: colors.lowTint.paleRed,
		secondaryBackground: colors.lowTint.redTint,
		primaryAccent: colors.base.blue2,
		...colors.monochrome,
		...colors.messaging,
	},
	works6: {
		primaryForeground: colors.base.blue2,
		primaryBackground: colors.lowTint.paleRed,
		secondaryBackground: colors.lowTint.redTint,
		primaryAccent: colors.base.blue2,
		...colors.monochrome,
		...colors.messaging,
	},
	sustainability: {
		primaryForeground: colors.base.green2,
		primaryBackground: colors.lowTint.turquoise,
		primaryAccent: colors.highTint.green,
		rippleBase: colors.base.red,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.base.blue,
		secondaryBackground: colors.lowTint.turquoise,
		secondaryAccent: colors.base.turquoise,
		...colors.monochrome,
		...colors.messaging,
	},
};

export default themes;
