import { css } from 'styled-components';
import pxToRem from './pxToRem';

export const below = (width: number, callback: Function) => (
	css`
    @media screen and (max-width: ${pxToRem(width - 0.063)}) {
      ${callback()}
    }
  `
);

export const above = (width: number, callback: Function) => (
	css`
    @media screen and (min-width: ${pxToRem(width)}) {
      ${callback()}
    }
  `
);

export const between = (minWidth: number, maxWidth: number, callback: Function) => (
	css`
    @media screen and (min-width: ${pxToRem(minWidth)}) and (max-width: ${pxToRem(maxWidth - 0.063)}) {
      ${callback()}
    }
  `
);

export const belowHeight = (height: number, callback: Function) => (
	css`
    @media screen and (max-height: ${pxToRem(height)}) {
      ${callback()}
    }
  `
);

export const aboveHeight = (height: number, callback: Function) => (
	css`
    @media screen and (min-height: ${pxToRem(height)}) {
      ${callback()}
    }
  `
);

export const hover = (callback: Function) => (
	css`
    @media screen and (hover) {
      ${callback()}
    }
  `
);

export const ie = (callback: Function) => (
	css`
    @media screen and (-ms-high-contrast: none) {
      ${callback()}
    }
  `
);

export const safari = (callback: Function) => (
	css`
		@media not all and (min-resolution:.001dpcm) {
			${callback()}
		}
	`
)
