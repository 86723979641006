import React, { useEffect } from 'react';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const SERVICE_NAME = 'site-client';

const NOT_TRACED_DOMAINS = new Set([
	'r.logr-ingest.com',
	'region1.google-analytics.com',
	'region1.analytics.google.com',
	'analytics.google.com',
	'www.googletagmanager.com',
	'snap.licdn.com',
	'js.monitor.azure.com',
	'px.ads.linkedin.com',
	'consentcdn.cookiebot.com',
	'gum.criteo.com',
	'measurement-api.criteo.com',
	'd.clarity.ms',
]);

function prepareApplicationInsights(
	appInsightsConnectionString?: string
): null | { insights: ApplicationInsights; reactPlugin: ReactPlugin } {
	if (!appInsightsConnectionString) {
		return null;
	}

	const browserHistory = createBrowserHistory({});

	const reactPlugin = new ReactPlugin();
	const telemetryInitializer = (envelope: ITelemetryItem) => {
		if (envelope.tags) {
			envelope.tags['ai.cloud.role'] = SERVICE_NAME;
		}

		const targetUrl: string = envelope.baseData?.target;
		if (!targetUrl) {
			// No need to filter the URL if there isn't one
			return true;
		}

		let domain: string;
		try {
			domain = new URL(targetUrl).hostname;
		} catch (e) {
			// If we can't parse the URL... i don't really mind?
			return true;
		}

		return !NOT_TRACED_DOMAINS.has(domain);
	};

	const insights = new ApplicationInsights({
		config: {
			connectionString: appInsightsConnectionString,
			// *** If you're adding the Click Analytics plug-in, delete the next line. ***
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: { history: browserHistory },
			},
		},
	});

	insights.addTelemetryInitializer(telemetryInitializer);

	insights.loadAppInsights();

	return {
		insights,
		reactPlugin,
	};
}

export function AppInsightsProvider({
	children,
	appInsightsConnectionString,
}: {
	children: React.ReactNode;
	appInsightsConnectionString?: string;
}) {
	const [appInsightsService, setAppInsightsService] = React.useState<null | ApplicationInsights>(
		null
	);
	const [reactPlugin, setReactPlugin] = React.useState<null | ReactPlugin>(null);

	useEffect(() => {
		// Running this in a useEffect will ensure we don't try to do it on the server during SSR
		if (appInsightsConnectionString && !appInsightsService) {
			const preparedInsights = prepareApplicationInsights(appInsightsConnectionString);
			setAppInsightsService(preparedInsights?.insights || null);
			setReactPlugin(preparedInsights?.reactPlugin || null);
		}
	}, []);

	if (!reactPlugin || !appInsightsService) {
		return children;
	}
	return (
		<AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
	);
}
